import { PatchJobAnalysis } from './job-analysis.service';

export class JobAnalysisGet {
  static readonly type = '@JobAnalysisState.get';
  constructor(public options?: { jobId: string }) {}
}

export class JobAnalysisPatch {
  static readonly type = '@JobAnalysisState.patch';
  constructor(
    public jobAnalysisId: string,
    public data: PatchJobAnalysis,
  ) {}
}
